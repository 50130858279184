<template>
  <div class="box">
    <div class="top">
      <div class="logo"><img src="@/assets/img/logo.png" style="width:100%;height:100%" alt=""></div>
      <div class="baogao">NutriCore 個人化營養分析</div>
      <div class="flex">
      </div>
    </div>
    <div class="vbvb">
      <div class="fist">
        <div class="fistqw">
          <div>PERSONAL INFORMATION</div>
        </div>
        <div class="fistw">
          <div>
            <el-table :data="tableData" style="width: 100%" :show-header="false">
              <el-table-column prop="date" label="日期" width="273">
              </el-table-column>
              <el-table-column prop="name" label="姓名" width="273">
              </el-table-column>
            </el-table>
          </div>
          <div>
            <el-table :data="tableDatas" style="width: 100%" :show-header="false">
              <el-table-column prop="date" label="日期" width="273">

              </el-table-column>
              <el-table-column prop="name" label="姓名" width="273">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="fiste"><em class="ad">MAIN PURPOSE：</em> <i class="sdd"> {{infop.purpose}} </i></div>

        <el-divider></el-divider>
        <div class="fiste"><em class="ad">Others：</em><i class="sdd">{{infop.other}}</i></div>
        <el-divider></el-divider>
        <div class="fiste"><em class="ad">Other questions：</em><i class="sdd">{{infop.otherQues}}</i></div>
        <el-divider></el-divider>
        <div class="fistq">
          <div>SYMPTOM & DISEASE</div>
        </div>

        <div class="fiste"><em class="e">A</em><em class="ed"> - </em><span>Cardiovascular：{{infop.acontent}}</span></div>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">B</em><em class="ed"> - </em><span>Hormones：{{infop.bcontent}}</span></div>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">C</em><em class="ed"> - </em><span>Immunity：{{infop.ccontent}}</span></div>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">D</em><em class="ed"> - </em><span>Neuropsychiatric systems：{{infop.dcontent}}</span></div>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">E</em><em class="ed"> - </em><span>Musculoskeletal： {{infop.econtent}}</span></div>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">F</em><em class="ed"> - </em><span>Intestinal system：{{infop.fcontent}}</span></div>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">G</em><em class="ed"> - </em><span>Living habit survey： {{infop.gcontent}}</span></div>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">H</em><em class="ed"> - </em><span>Others：{{infop.hcontent}}</span></div>
        <el-divider></el-divider>
        <div class="fistq">
          <div>MEDICATION HX</div>
        </div>
        <div class="fiste"><em class="e">I</em><em class="ed"> - </em>MEDICATION HX：<span>{{infop.icontent}}</span></div>
        <el-divider></el-divider>
        <div class="fistq">
          <div>NUTRITION ANALYSIS</div>
        </div>
        <div style="height:20px"></div>
        <div class="count-chart-wrapper" style="flex-wrap:wrap">
          <echart :index='index+1' :title="val.nutrientName" :xdata='val.x' :ydata='val.y' v-for='(val,index) in echartlist' :key="index" />
        </div>
        <div class="beizhu">* Note: the nutritional analysis chart is presented only according to the results of your answer and the ratio of nutritional contribution, and is not equal to the actual concentration index of the body,If you need to further understand the connotation or countermeasures, please contact your doctor and consultant for complete analysis and evaluation</div>
        <div class="fistq">
          <div>BIOLOGICAL FUNCTIONS</div>
        </div>
        <div class="fiste">
          <em class="e">J</em><em class="ed"> - </em>ASSIMILATION
        </div>
        <p>
          {{infop.jcontent}}
        </p>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">K</em><em class="ed"> - </em>ELIMINATION</div>
        <p>
          {{infop.kcontent}}
        </p>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">L</em><em class="ed"> - </em>DEFENSE & REPAIR</div>
        <p>
          {{infop.lcontent}}
        </p>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">M</em><em class="ed"> - </em>COMMUNICATION</div>
        <p>
          {{infop.mcontent}}
        </p>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">N</em><em class="ed"> - </em>TRANSPORT</div>
        <p>
          {{infop.ncontent}}
        </p>
        <el-divider></el-divider>

        <div class="fiste"><em class="e">P</em><em class="ed"> - </em>ENERGY</div>
        <p>
          {{infop.pcontent}}
        </p>
        <el-divider></el-divider>
        <div class="fiste"><em class="e">Q</em><em class="ed"> - </em>STRUCTURAL INTEGRITY</div>
        <p>
          {{infop.qcontent}}
        </p>
        <el-divider></el-divider>
        <div class="fistq">
          <div>BIOLOGICAL FUNCTION ANALYSIS</div>
        </div>
        <div class="count-chart-wrapper">

          <div class="count-chart" ref="charts"></div>
          <!--必须设置宽高-->
        </div>
        <div class="fistq">
          <div>THANKYOU</div>
        </div>
        <div style="height:0.5vw"></div>
        <div class="fistw">
          <div class="new_td">
            <el-table :data="tableData1" style="width: 100%" :show-header="false">
              <el-table-column header-align="center" prop="date" width="273">
              </el-table-column>
              <el-table-column header-align="center" prop="name" width="273">
              </el-table-column>
            </el-table>
          </div>

          <div class="new_td">
            <el-table :data="tableDatas1" style="width: 100%" :show-header="false">
              <el-table-column header-align="center" prop="date" width="273">
              </el-table-column>
              <el-table-column header-align="center" prop="name" width="273">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="fistq">
          <div>THANKYOU</div>
        </div>
        <div style="height:0.5vw"></div>
        <div class="fistw">
          <div class="new_td">
            <el-table :data="tableRowData" :span-method="mergeRowMethod" border style="width: 100%" :header-cell-style="{background:'#FBFDFB',color:'#333333'}">
              <el-table-column header-align="center" align="center" prop="sort" label="缺乏營養" width="200">

              </el-table-column>
              <el-table-column header-align="center" prop="name" label="食物類別">
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="fistq">
          <div>THANKYOU</div>
        </div>
        <!-- <div class="burtom">
          <div class="burtom1"><span style="width:500px">
              Thank you for this analysis. The report is the report result of the dynamic
              output of the analysis system. <br>If you have anyquestions about the report,
              you can join the customer service
              <span><a :href="infpourleee.content">{{infpourleee.content}}</a></span>
            </span>
          </div>
          <div class="burtom2"><img :src="infpourl.content" alt=""></div>
        </div> -->
        <div class="burtom">
          <div class="burtom1">Line, appointment consultant for more
            in-depth instructions and suggestions.
            <br>
            <span><a :href="infpourle.content">{{infpourle.content}}</a></span>
          </div>
          <div class="burtom2"><img :src="infpourlee.content" alt=""></div>
        </div>

      </div>
    </div>
  </div>

</template>
<script>
import echart from "./components/echart.vue"
import * as echarts from "echarts"
import {
  getdetail,
  getInfoo,
  getInfof,
  getOne,
  get_jianyi,
} from "../../api/edg.js"
import { getInfo } from "../../api/infomtion.js"
import axios from "axios"
export default {
  components: { echart },
  data() {
    return {
      activeName: "second",
      infop: {},
      infpourl: {},
      infpourle: {},
      infpourlee: {},
      infpourleee: {},
      chart: [],
      chartc: [],
      chart2: [],
      chartc2: [],
      chart3: [],
      chartc3: [],
      chart4: [],
      chartc4: [],
      chartss: [],
      chartsss: [],
      chartss2: [],
      chartsss2: [],
      chartss3: [],
      chartsss3: [],
      chartss4: [],
      chartsss4: [],
      charta: [],
      chartaa: [],
      colorList: [
        //设置发货柱子的，普通的柱子颜色
        "#69D3BE",
        "#9D9E9F",
        "#F88282",
        "#69D3BE",
        "#9D9E9F",
        "#F88282",
        "#69D3BE",
        "#9D9E9F",
        "#F88282",
        "#69D3BE",
        "#9D9E9F",
        "#F88282",
        "#F88282",
        "#69D3BE",
        "#9D9E9F",
      ],
      colorList2: [
        //设置发货柱子的，柱子实现渐变色
        ["#0282DE", "#3F28D0"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
        ["#0282DE", "#3F28D0"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
        ["#67E0E3", "#0181DE"],
        ["#0282DE", "#3F28D0"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
        ["#FED701", "#E66938"],
        ["#67E0E3", "#0181DE"],
      ],
      color: [
        "#e0e6f1",
        "#e0e6f1",
        "#e0e6f1",
        "#e0e6f1",
        "#e0e6f1",
        "#ff5252",

        "#e0e6f1",
        "#e0e6f1",
      ],
      info: {},
      tableData: [],
      tableDatas: [],
      tableData1: [],
      tableDatas1: [],
      tableData_new: [
        {
          date: "膳食纖維",
          name: "◆菇類:白茯苓、乾木耳(紅耳仔)、乾銀耳",
        },
        {
          date: "膳食纖維",
          name: "◆藻類:洋菜",
        },
        {
          date: "膳食纖維",
          name: "◆油質堅果類:山粉圓",
        },
        {
          date: "鈉",
          name: "◆蔬菜類:梅乾菜、乾麒麟菜、乾裙帶菜根、乾裙帶菜",
        },
        {
          date: "鈉",
          name: "◆豆蛋魚肉類:海蜇皮",
        },
      ],
      chart_png: "",
      chart_png2: "",
      chart_png3: "",
      chart_png4: "",
      char_npg: "",
      echartlist: [],
    }
  },
  mounted() {},
  created() {
    this.getdetail()
    this.get_tu() //获取柱狀圖1
    this.get_tuw() //获取柱狀圖2
    this.getone()
    this.getonev()
    this.getonevv()
    this.getonevvv()
    this.get_jianyi()
  },
  methods: {
    get_jianyi() {
      get_jianyi(this.$route.query.id)
        .then((res) => {
          this.list = res.data
          console.log(11, this.list)
          this.tableData_s = this.list.map((item) => {
            let obj = {}

            if (item.egg) {
              obj.egg = "◆豆蛋魚肉類：" + item.egg
            }
            if (item.cereals) {
              obj.cereals = "◆全榖雜糧類：" + item.cereals
            }
            if (item.dairy) {
              obj.dairy = "◆乳品類：" + item.dairy
            }
            if (item.vegetable) {
              obj.vegetable = "◆蔬菜類：" + item.vegetable
            }
            if (item.fruit) {
              obj.fruit = "◆水果類：" + item.fruit
            }
            if (item.nuts) {
              obj.nuts = "◆油質堅果類：" + item.nuts
            }
            console.log("obj", obj)

            //清楚数组中的null
            // let obj2 = this.delObjectKey(obj)
            // console.log("obj2", obj2)
            //对象中的值转换成数组
            let arr = Object.values(obj)
            console.log(arr)
            let new_arr = arr.map((item) => {
              return { name: item }
            })
            console.log("处理后的数组", new_arr)
            return {
              id: item.id,
              sort: item.nutrients,
              children: new_arr,
            }
          })
          this.loadRowData()
        })
        .catch((err) => {
          console.log(12, err)
        })
    },
    loadRowData() {
      this.tableRowData = []
      let originArr = JSON.parse(JSON.stringify(this.tableData_s)) // 普通的数据转换，将后台格式转换成element表格需要的格式
      if (Array.isArray(originArr)) {
        originArr.map((res) => {
          if (Array.isArray(res.children)) {
            res.children.map((subRes, subIndex) => {
              let content = {}
              content = subRes
              content["sort"] = res.sort // 重点！赋值合并的行数数值，只需要取子循环的第一个数赋值待合并的行数即可
              if (subIndex == 0) {
                content["rowNum"] = res.children.length
              }
              this.tableRowData.push(content)
            })
          }
        })
      }
      console.log("tableRowData==============", this.tableRowData)
    },

    mergeRowMethod({ row, column, rowIndex, columnIndex }) {
      // columnIndex 代表列数，从0开始计数,我们要合并的字段属于第一列，取0
      if (columnIndex == 0) {
        return {
          rowspan: row.rowNum, // 待合并行数 -- 合并的行数长度就等于之前赋值的子数据的长度；未赋值的即表示0，不显示
          colspan: row.rowNum > 0 ? 1 : 0, // 待合并列数 -- 合并的列数自身占一列，被合并的要返回0，表示不显示
        }
      }
    },
    handleClick(tab, event) {},
    getInfo() {
      getInfo().then((res) => {
        this.info = res.data
      })
    },
    upto() {
      window.location.href = "https://www.drnutri.tw/"
    },
    getout() {
      this.loading = true
      logout()
      this.$store
        .dispatch("LogOut")
        .then((res) => {
          this.$message({
            message: "退出登入成功",
            type: "success",
          })

          this.$router.push("/")
          removeToken(res.data)
        })
        .catch(() => {
          this.loading = false
        })
    },
    //  APP-配置查询接口
    getone() {
      getOne({ code: "customer_service" })
        .then((res) => {
          this.infpourl = res.data
        })
        .catch((err) => {
          //处理返回错误
          // console.log(12, err)
        })
    },
    getonev() {
      getOne({ code: "literature_url" })
        .then((res) => {
          this.infpourle = res.data
        })
        .catch((err) => {
          //处理返回错误
          // console.log(12, err)
        })
    },
    getonevv() {
      getOne({ code: "literature_qrcode" })
        .then((res) => {
          this.infpourlee = res.data
        })
        .catch((err) => {
          //处理返回错误
          // console.log(12, err)
        })
    },
    getonevvv() {
      getOne({ code: "customer_service_url" })
        .then((res) => {
          this.infpourleee = res.data
        })
        .catch((err) => {
          //处理返回错误
          // console.log(12, err)
        })
    },
    getinfodata() {
      axios({
        method: "POST",
        url: `${process.env.VUE_APP_BASE_API}/questionnaire/questionnaireReport`,
        data: {
          id: this.$route.query.id,
          core: this.char_npg,
          vicTaMin: this.chart_png,
          calcium: this.chart_png2,
          fishOil: this.chart_png3,
          magnesium: this.chart_png4,
        },
        headers: {
          Authorization: "Bearer " + getToken(),
        },
        responseType: "blob",
      })
        .then(function (res) {
          let url = window.URL.createObjectURL(new Blob([res.data]), {
            type: "application/pdf;charset=UTF-8",
          })
          let link = document.createElement("a")
          link.style.display = "none"
          link.href = url
          let timestamp = new Date().getTime()
          link.download = timestamp + ".pdf"
          document.body.appendChild(link)
          link.click()
          window.URL.revokeObjectURL(link.href)
        })
        .catch(function (error) {
          // console.log(error)
        })
    },
    getInf() {
      this.getinfodata()
    },
    getFullCanvasDataURL() {
      let my_chart_dom = this.$refs.chart.getElementsByTagName("canvas")[0]
      let chart_png = my_chart_dom.toDataURL()
      this.chart_png = chart_png
      // console.log("chart的dom元素", chart_png)
    },
    getFullCanvasDataURL2() {
      let my_chart_dom = this.$refs.chart2.getElementsByTagName("canvas")[0]
      let chart_png = my_chart_dom.toDataURL()
      this.chart_png2 = chart_png
      // console.log("chart2的dom元素", chart_png2)
    },
    getFullCanvasDataURL3() {
      let my_chart_dom = this.$refs.chart3.getElementsByTagName("canvas")[0]
      let chart_png = my_chart_dom.toDataURL()
      this.chart_png3 = chart_png
      // console.log("chart的dom元素", chart_png3)
    },
    getFullCanvasDataURL4() {
      let my_chart_dom = this.$refs.chart4.getElementsByTagName("canvas")[0]
      let chart_png = my_chart_dom.toDataURL()
      this.chart_png4 = chart_png
      // console.log("chart4的dom元素", chart_png4)
    },
    getFullCanvas() {
      let my_chart_do = this.$refs.charts.getElementsByTagName("canvas")[0]
      let chart_pn = my_chart_do.toDataURL()
      this.char_npg = chart_pn
      // console.log("char元素", chart_pn)
    },

    //获取柱狀圖1
    get_tu() {
      getInfoo({ id: this.$route.query.id })
        .then((res) => {
          this.echartlist = res.data
          this.initChart()
        })
        .catch((err) => {
          //处理返回错误
          // console.log(12, err)
        })
    },
    get_tu2() {
      getInfoo2({ id: this.$route.query.id })
        .then((res) => {
          this.chart2 = res.data
          // console.log("这是柱狀圖1", this.chart2)
          this.chartss2 = this.chart2.map(function (item) {
            return item.xvalue
          })

          this.chartsss2 = this.chart2.map(function (item) {
            return item.yvalue
          })
          this.initChart2()
        })
        .catch((err) => {})
    },
    get_tu3() {
      getInfoo3({ id: this.$route.query.id })
        .then((res) => {
          this.chart3 = res.data
          this.chartss3 = this.chart3.map(function (item) {
            return item.xvalue
          })

          this.chartsss3 = this.chart3.map(function (item) {
            return item.yvalue
          })
          this.initChart3()
        })
        .catch((err) => {})
    },
    get_tu4() {
      getInfoo4({ id: this.$route.query.id })
        .then((res) => {
          this.chart4 = res.data
          this.chartss4 = this.chart4.map(function (item) {
            return item.xvalue
          })

          this.chartsss4 = this.chart4.map(function (item) {
            return item.yvalue
          })
          this.initChart4()
        })
        .catch((err) => {})
    },
    //获取柱狀圖2
    get_tuw() {
      getInfof({ id: this.$route.query.id })
        .then((res) => {
          this.chartc = res.data
          this.charta = this.chartc.map(function (item) {
            return item.xvalue
          })
          this.chartaa = this.chartc.map(function (item) {
            return item.yvalue
          })

          this.initCharts()
        })
        .catch((err) => {})
    },
    // 初始化图表样式
    initChart() {
      this.chart = echarts.init(this.$refs.chart)
      let _this = this
      this.chart.setOption({
        animation: false,
        grid: {
          left: "50",
          right: "60",
        },
        legend: {
          show: false,
          data: this.legends,
        },
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },

        toolbox: {
          //可视化的工具箱
          show: true,
          feature: {
            // dataView: {
            //   //数据视图
            //   show: true,
            // },
            restore: {
              //重置
              show: true,
            },
            dataZoom: {
              //数据缩放视图
              show: true,
            },
            saveAsImage: {
              //保存图片
              show: true,
            },
            magicType: {
              //动态类型切换
              type: ["bar", "line"],
            },
          },
        },
        xAxis: {
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          type: "category",
          data: this.chartss, //X轴显示
          axisLabel: {
            color: "#333",
            interval: 0, //0：不隔行显示，1：隔一行显示
          },
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            fontWeight: "bold",
            align: "left",
            padding: [0, 10, 10, 0],
            color: "#ffffff",
          },
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          axisLabel: {
            color: "#333",
            formatter: `{value}`, //Y轴的显示形式，value，percent
          },
        },
        title: [
          {
            left: "left",
            text: "Victamin chart",
            textStyle: {
              color: "#333333",
              fontSize: 16,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            //实现渐变色的柱子
            data: this.chartsss, //显示的数据
            type: "bar",
            smooth: true, // 平滑
            symbol: "none",
            lineStyle: {
              color: "#FF5858",
            },
            barMaxWidth: 30, //设置柱子的宽度
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#222",
                    fontSize: 14,
                  },
                },
                color: (params) => {
                  let colorList = _this.colorList2 //柱子的颜色是普通的颜色
                  let index = params.dataIndex
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length
                  }
                  // return colorList[index]
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //渐变色使用方法
                    { offset: 0, color: colorList[index][0] },
                    { offset: 1, color: colorList[index][1] },
                  ])
                },
              },
            },
          },
        ],
      })
      this.getFullCanvasDataURL()
    },
    initChart2() {
      this.chart2 = echarts.init(this.$refs.chart2)
      let _this = this
      this.chart2.setOption({
        animation: false,
        grid: {
          left: "50",
          right: "60",
        },
        legend: {
          show: false,
          data: this.legends,
        },
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        toolbox: {
          //可视化的工具箱
          show: true,
          feature: {
            // dataView: {
            //   //数据视图
            //   show: true,
            // },
            restore: {
              //重置
              show: true,
            },
            dataZoom: {
              //数据缩放视图
              show: true,
            },
            saveAsImage: {
              //保存图片
              show: true,
            },
            magicType: {
              //动态类型切换
              type: ["bar", "line"],
            },
          },
        },
        xAxis: {
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          type: "category",
          data: this.chartss2, //X轴显示
          axisLabel: {
            color: "#333",
            interval: 0, //0：不隔行显示，1：隔一行显示
          },
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            fontWeight: "bold",
            align: "left",
            padding: [0, 10, 10, 0],
            color: "#ffffff",
          },
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          axisLabel: {
            color: "#333",
            formatter: `{value}`, //Y轴的显示形式，value，percent
          },
        },
        title: [
          {
            left: "left",
            text: "Calcium chart",
            textStyle: {
              color: "#333333",
              fontSize: 16,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            //实现渐变色的柱子
            data: this.chartsss2, //显示的数据
            type: "bar",
            smooth: true, // 平滑
            symbol: "none",
            lineStyle: {
              color: "#FF5858",
            },
            barMaxWidth: 30, //设置柱子的宽度
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#222",
                    fontSize: 14,
                  },
                },
                color: (params) => {
                  let colorList = _this.colorList2 //柱子的颜色是普通的颜色
                  let index = params.dataIndex
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length
                  }
                  // return colorList[index]
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //渐变色使用方法
                    { offset: 0, color: colorList[index][0] },
                    { offset: 1, color: colorList[index][1] },
                  ])
                },

                // color: (params) => {
                //   let colorList = _this.colorList2 //实现柱子的渐变色数组
                // let index = params.dataIndex //dataIndex  data中数据的下标
                // if (params.dataIndex >= colorList.length) {
                //   index = colorList.length - params.dataIndex
                // }
                //   return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //     //渐变色使用方法
                //     { offset: 0, color: colorList[index][0] },
                //     { offset: 1, color: colorList[index][1] },
                //   ])
                // },
              },
            },
          },
        ],
      })
      this.getFullCanvasDataURL2()
    },
    initChart3() {
      this.chart3 = echarts.init(this.$refs.chart3)
      let _this = this
      this.chart3.setOption({
        animation: false,
        grid: {
          left: "50",
          right: "60",
        },
        legend: {
          show: false,
          data: this.legends,
        },
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        toolbox: {
          //可视化的工具箱
          show: true,
          feature: {
            // dataView: {
            //   //数据视图
            //   show: true,
            // },
            restore: {
              //重置
              show: true,
            },
            dataZoom: {
              //数据缩放视图
              show: true,
            },
            saveAsImage: {
              //保存图片
              show: true,
            },
            magicType: {
              //动态类型切换
              type: ["bar", "line"],
            },
          },
        },
        xAxis: {
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          type: "category",
          data: this.chartss3, //X轴显示
          axisLabel: {
            color: "#333",
            interval: 0, //0：不隔行显示，1：隔一行显示
          },
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            fontWeight: "bold",
            align: "left",
            padding: [0, 10, 10, 0],
            color: "#ffffff",
          },
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          axisLabel: {
            color: "#333",
            formatter: `{value}`, //Y轴的显示形式，value，percent
          },
        },
        title: [
          {
            left: "left",
            text: "fish oil chart",
            textStyle: {
              color: "#333333",
              fontSize: 16,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            //实现渐变色的柱子
            data: this.chartsss3, //显示的数据
            type: "bar",
            smooth: true, // 平滑
            symbol: "none",
            lineStyle: {
              color: "#FF5858",
            },
            barMaxWidth: 30, //设置柱子的宽度
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#222",
                    fontSize: 14,
                  },
                },
                color: (params) => {
                  let colorList = _this.colorList2 //柱子的颜色是普通的颜色
                  let index = params.dataIndex
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length
                  }
                  // return colorList[index]
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //渐变色使用方法
                    { offset: 0, color: colorList[index][0] },
                    { offset: 1, color: colorList[index][1] },
                  ])
                },
              },
            },
          },
        ],
      })
      this.getFullCanvasDataURL3()
    },
    initChart4() {
      this.chart4 = echarts.init(this.$refs.chart4)
      let _this = this
      this.chart4.setOption({
        animation: false,
        grid: {
          left: "50",
          right: "60",
        },
        legend: {
          show: false,
          data: this.legends,
        },
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        toolbox: {
          //可视化的工具箱
          show: true,
          feature: {
            // dataView: {
            //   //数据视图
            //   show: true,
            // },
            restore: {
              //重置
              show: true,
            },
            dataZoom: {
              //数据缩放视图
              show: true,
            },
            saveAsImage: {
              //保存图片
              show: true,
            },
            magicType: {
              //动态类型切换
              type: ["bar", "line"],
            },
          },
        },
        xAxis: {
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          type: "category",
          data: this.chartss4, //X轴显示
          axisLabel: {
            color: "#333",
            interval: 0, //0：不隔行显示，1：隔一行显示
          },
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            fontWeight: "bold",
            align: "left",
            padding: [0, 10, 10, 0],
            color: "#ffffff",
          },
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          axisLabel: {
            color: "#333",
            formatter: `{value}`, //Y轴的显示形式，value，percent
          },
        },
        title: [
          {
            left: "left",
            text: "Magnesium chart",
            textStyle: {
              color: "#333333",
              fontSize: 16,
              fontWeight: "normal",
            },
          },
        ],
        series: [
          {
            //实现渐变色的柱子
            data: this.chartsss4, //显示的数据
            type: "bar",
            smooth: true, // 平滑
            symbol: "none",
            lineStyle: {
              color: "#FF5858",
            },
            barMaxWidth: 30, //设置柱子的宽度
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#222",
                    fontSize: 14,
                  },
                },
                color: (params) => {
                  let colorList = _this.colorList2 //柱子的颜色是普通的颜色
                  let index = params.dataIndex
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length
                  }
                  // return colorList[index]
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //渐变色使用方法
                    { offset: 0, color: colorList[index][0] },
                    { offset: 1, color: colorList[index][1] },
                  ])
                },
              },
            },
          },
        ],
      })
      this.getFullCanvasDataURL4()
    },
    // 初始化图表样式
    initCharts() {
      this.chart = echarts.init(this.$refs.charts)
      let _this = this
      this.chart.setOption({
        animation: false,
        grid: {
          left: "50",
          right: "50",
        },
        legend: {
          show: false,
          data: this.legends,
        },
        tooltip: {
          trigger: "axis",
          show: true,
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        toolbox: {
          //可视化的工具箱
          show: true,
          feature: {
            // dataView: {
            //   //数据视图
            //   show: true,
            // },
            restore: {
              //重置
              show: true,
            },
            // dataZoom: {
            //   //数据缩放视图
            //   show: true,
            // },
            saveAsImage: {
              //保存图片
              show: true,
            },
            magicType: {
              //动态类型切换
              type: ["bar", "line"],
            },
          },
        },
        xAxis: {
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          type: "category",
          data: this.charta, //X轴显示
          axisLabel: {
            color: "#333",
            interval: 0, //0：不隔行显示，1：隔一行显示
          },
        },
        yAxis: {
          type: "value",
          nameTextStyle: {
            fontWeight: "bold",
            align: "left",
            padding: [0, 0, 0, 0],
            color: "#ffffff",
          },
          min: 0, //取0为最小刻度
          max: 8, //取100为最大刻度

          // min: "dataMin", //取最小值为最小刻度
          // max: "dataMax", //取最大值为最大刻度

          // min: function (value) {
          //   //取最小值向下取整为最小刻度
          //   return Math.floor(value.min)
          // },
          // max: function (value) {
          //   //取最大值向上取整为最大刻度
          //   return Math.ceil(value.max)
          // },

          interval: 1, // 步长
          // scale: true, //自适应
          minInterval: 15, //分割刻度
          axisLine: { show: false }, // 轴线
          axisTick: { show: false }, // 刻度
          axisLabel: {
            color: "#333",
            formatter: `{value}`, //Y轴的显示形式，value，percent
          },
          splitLine: {
            //修改背景线条样式
            show: true, //是否展示
            lineStyle: {
              color: this.color, //   ["#ffcc00", "#009999"], //线条颜色

              type: "solid", //线条样式，默认是实现，dashed是虚线
            },
          },
        },
        series: [
          {
            type: "bar",
            //实现渐变色的柱子
            data: this.chartaa, //显示的数据

            smooth: true, // 平滑
            symbol: "none",
            lineStyle: {
              color: "#FF5858",
            },
            barMaxWidth: 30, //设置柱子的宽度
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式

                    //判断数值是否大于5，如果大于5则数值为红色，否则为黑色

                    color: "#333",
                    fontSize: 14,
                  },
                },
                // color: (params) => {
                //   let colorList = _this.colorList2 //柱子的颜色是普通的颜色
                //   let index = params.dataIndex
                //   if (params.dataIndex >= colorList.length) {
                //     index = params.dataIndex - colorList.length
                //   }
                //   // return colorList[index]
                //   return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                //     //渐变色使用方法
                //     { offset: 0, color: colorList[index][0] },
                //     { offset: 1, color: colorList[index][1] },
                //   ])
                // },

                color: (params) => {
                  let colorList = _this.colorList2 //实现柱子的渐变色数组
                  let index = params.dataIndex //dataIndex  data中数据的下标
                  if (params.dataIndex >= colorList.length) {
                    index = params.dataIndex - colorList.length
                  }
                  return new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //渐变色使用方法
                    { offset: 0, color: colorList[index][0] },
                    { offset: 1, color: colorList[index][1] },
                  ])
                },
              },
            },
          },
        ],
      })
      this.getFullCanvas()
    },

    getdetail() {
      getdetail({ id: this.$route.query.id }).then((res) => {
        this.infop = res.data
        this.tableData = [
          {
            date: "姓 名：",
            name: this.infop.name,
          },
          {
            date: "電 話：",
            name: this.infop.phone,
          },
          {
            date: "性 別：",

            name: this.infop.sex,
          },
          {
            date: "飲食習慣：",
            name: this.infop.eatingHabits,
          },
          {
            date: "體重：",
            name: this.infop.weight,
          },
          {
            date: "BMI區間：",
            name: this.infop.bmiType,
          },
          {
            date: "每日需要卡路里：",
            name: this.infop.calories,
          },
          {
            date: "工作類別：",
            name: this.infop.workType,
          },
        ]

        this.tableDatas = [
          {
            date: "填寫日期：",
            name: this.infop.createTime,
          },
          {
            date: "LINE  I D：",
            name: this.infop.lineId,
          },
          {
            date: "年齡範圍：",
            name: this.infop.age,
          },
          {
            date: "茹素細節：",
            name: this.infop.info,
          },
          {
            date: "身高：",
            name: this.infop.height,
          },
          {
            date: "BMI：",
            name: this.infop.bmi,
          },
          {
            date: "活動量：",
            name: this.infop.exercise,
          },
          {
            date: "保健習慣：",
            name: this.infop.healthHabits,
          },
        ]
        this.tableData1 = [
          {
            date: "全穀雜糧類：",
            name: this.infop.recommendIntake.cereals + "份",
          },
          {
            date: "水果類：",
            name: this.infop.recommendIntake.fruit + "份",
          },
          {
            date: "乳品類：",
            name: this.infop.recommendIntake.dairy + "份",
          },
        ]
        this.tableDatas1 = [
          {
            date: "蔬菜類：",
            name: this.infop.recommendIntake.vegetable + "份",
          },
          {
            date: "豆魚蛋肉類：",
            name: this.infop.recommendIntake.egg + "份",
          },
          {
            date: "油脂與堅果種子類：",
            name: this.infop.recommendIntake.nuts + "份",
          },
        ]
      })
    },
    //袋装表格
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex === 1) {
        return "warning-row"
      } else if (rowIndex === 3) {
        return "success-row"
      }
      return ""
    },
  },
}
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.beizhu {
  margin-top: 8px;
  font-size: 10px;
  // font-weight: bold;
  padding-right: 20px;
  color: #666;
  text-align: right;
}
em.ad {
  display: block;
  font-style: normal;
  text-align: center;
  flex-shrink: 0;
  font-size: 15px;
  color: #606266;
}
.sdd {
  display: block;
  font-style: normal;

  font-size: 15px;
  color: #606266;
}
em.e {
  display: block;
  font-style: normal;
  font-weight: 500;
  width: 2vw;
  text-align: center;
  flex-shrink: 0;
  // background: #707070;
}
em.ed {
  display: block;
  font-style: normal;
  font-weight: 500;
  // width: vw;
  margin-right: 5px;
  text-align: center;
  flex-shrink: 0;
  // background: #707070;
}
.burtom {
  display: flex;
  margin: 20px 0;
  .burtom1 {
    //  height: 68px;
    font-size: 18px;
    font-weight: 400;
    //line-height: 68px;
    color: #333333;
  }
  .burtom2 {
    width: 68px;
    height: 68px;
    background: rgba(0, 0, 0, 0);
    opacity: 1;
    margin-left: 30px;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
}
.flex {
  //width: auto;
  display: flex;
  float: right;
  padding: 0 0;
  margin-right: 4%;
  margin-left: auto;
}
.button {
  display: flex;
  float: right;
  margin-bottom: 15px;
}
/deep/.el-button {
  margin-left: 0 !important;
  margin-right: 20px;
}
.topv {
  width: 1200px;
  height: 97px;
  //   background: rgb(198, 226, 187);
  margin: 0 auto;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 15px;
  .one {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    opacity: 1;
  }
  .two {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 97px;
    color: #707070;
    opacity: 1;
    margin-left: 580px;
  }
  .two:hover {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    margin-left: 580px;
    border-bottom: 2px solid #000;
  }
  .three {
    height: 97px;
    font-size: 30px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 97px;
    color: #333333;
    margin-left: 60px;
    border-bottom: 2px solid #000;
  }
}
//带状表格
/deep/.el-table .warning-row {
  background: oldlace;
}

/deep/.el-table .success-row {
  background: #f0f9eb;
}
//查看
/deep/.el-button {
  width: 62px;
  height: 30px;
  color: #fff;
  background: rgba(150, 185, 100, 0.39);
  opacity: 1;
  margin-left: 850px;
}
// .vbvb {
//   width: 1064px;
//   margin: 0 auto;
//   display: flex;
// }
//切换蓝
/deep/.el-tabs__item {
  font-size: 30px;
}
.count-chart-wrapper {
  // display: flex;
  // justify-content: space-between;
  margin: 0 auto;
  background-color: #fff;
}
.count-chart {
  position: relative;
  margin: 0 auto;
  width: 1140px;
  height: 500px;
  canvas {
    width: 100%;
    height: 100%;
  }
}
.count-charts {
  position: relative;
  margin: 0 auto;
  width: 1140px;
  height: 500px;
  canvas {
    width: 100%;
    height: 100%;
  }
}
.box {
  width: 100%;
  height: 1080px;
  min-width: 1300px;
  background: rgba(255, 255, 255, 0.39);
}
/* 顶部 */
.top {
  width: 100%;
  //height: 143px;
  min-width: 1300px;
  background: rgb(237, 244, 237);

  display: flex;
  align-items: center;
}
.logo {
  width: 405px;
  height: 143px;
  line-height: 0;
  margin: auto 0;
  margin-left: 30px;
  padding: 13px 0;
}
.baogao {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin: 0 auto;
}
.baogao:hover {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin: 0 auto;
}
.shop {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #707070;
  // border-bottom: 3px solid #707070;
  margin-left: 5%;
}
.shop:hover {
  width: auto;
  height: 143px;
  font-size: 30px;
  font-weight: bold;
  line-height: 143px;
  color: #333333;
  border-bottom: 3px solid #333333;
  margin-left: 5%;
}
.hend {
  width: 30px;
  height: 30px;
  line-height: 0;

  //margin-right: 370px
}
.login {
  width: 44px;
  height: 30px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
  margin-left: 10px;
}
.name {
  height: 30px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 30px;
  color: #333333;
}
.fist {
  width: 1140px;
  margin: 0 auto;
  .fistq {
    height: 50px;
    background: rgb(237, 244, 237);
    margin-top: 10px;
    div {
      height: 50px;
      font-size: 20px;
      font-weight: 500;
      line-height: 50px;
      color: #333333;
      margin-left: 20px;
    }
  }
  .fistqw {
    height: 50px;
    background: rgb(237, 244, 237);
    margin-top: 15px;
    clear: both;
    div {
      height: 50px;
      font-size: 20px;
      font-weight: 500;
      line-height: 50px;
      color: #333333;
      margin-left: 20px;
    }
  }
  .fistw {
    display: flex;
    justify-content: space-between;
    div {
      width: 48%;
    }

    .new_td {
      width: 100%;
    }
  }
  .fiste {
    display: flex;
    padding-left: 10px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 500;
    line-height: 25px;
    color: #333333;
    margin: 10px 10px 10px 0;
    span {
      font-size: 15px;

      font-weight: 500;
      //  line-height: 25px;
      color: #333333;
    }
  }
  .fiste:first-letter {
    margin-left: -2em;
  }
  p {
    // text-indent: 35px;
    // font-size: 18px;
    // font-weight: bold;
    // line-height: 25px;
    // margin-bottom: 10px;
    // margin-left: 20px;
    // color: #333333;

    letter-spacing: 1px;
    // text-indent: 35px;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 10px;
    padding-left: 45px;
    color: #333333;
  }
}
.fist .fiste span[data-v-354f74fb] {
  // width: 10px;
  //  height: 50px;
  font-weight: 500;
}
</style>>