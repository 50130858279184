import request from '@/utils/request'
// 营养柱状图
export function getInfoo(data) {
    return request({
        url: '/questionnaire/nutrientsEN',
        method: 'get',
        params: data,
    })
}
export function getInfoo2(data) {
    return request({
        url: '/questionnaire/calciumEN',
        method: 'get',
        params: data,
    })
}
export function getInfoo3(data) {
    return request({
        url: '/questionnaire/fishOilEN',
        method: 'get',
        params: data,
    })
}
export function getInfoo4(data) {
    return request({
        url: '/questionnaire/magnesiumEN',
        method: 'get',
        params: data,
    })
}
//个人信息 /questionnaire/coreSymptoms
export function getdetail(data) {
    return request({
        url: '/questionnaire/detail',
        method: 'get',
        params: data,
    })
}
// 核心症状图
export function getInfof(data) {
    return request({
        url: '/questionnaire/coreSymptomsEN',
        method: 'get',
        params: data,
    })
}
// APP-配置查询接口
export function getOne(data) {
    return request({
        url: '/setting/getOne',
        method: 'get',
        params: data,
    })
}
//64
export function getOned(data) {
    return request({
        url: '/questionnaire/questionnaireReport',
        method: 'post',
        data: data,
        responseType: "blob",
    })
}
export function get_jianyi(data) {
    return request({
        url: '/questionnaire/food/' + data,
        method: 'get',
    })
}